import React, { Fragment } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Header = ({ location, siteTitle }) => {
  return (
    <Container>
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `1.45rem 1.0875rem`,
        }}
      >
        <Title location={location} data-glitch={siteTitle}>
          <Logo to="/">{siteTitle}</Logo>
        </Title>
        {location && location.pathname.match('/posts') ? (
          <Fragment>
            {' '}
            <strong style={{ fontWeight: 800 }}>/</strong>{' '}
            <span>
              <Path>
                <Link to="posts">Posts</Link>
              </Path>
            </span>
          </Fragment>
        ) : null}
      </div>
    </Container>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

const Path = styled.h2`
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 3px;
  text-indent: 3px;
  display: inline-block;
  a {
    color: white;
    border-bottom: none;
  }
  :after {
    content: '';
    display: block;
    margin: auto;
    height: 3px;
    width: 0px;
    background: transparent;
    transition: width 300ms ease, background-color 300ms ease;
  }
  &:hover:after {
    width: 100%;
    background-color: white;
  }
`;

const Title = styled.h1`
  margin: 0;
  a {
    color: white;
  }
  ${props =>
    props.location && props.location.pathname === '/'
      ? `font-size: 48px;`
      : `
      font-size: inherit;
      text-transform: uppercase;
      letter-spacing: 3px;
      text-indent: 3px;
      display: inline-block;
      `};
`;

const Container = styled.header`
  margin: 32px 0;
`;

const Logo = styled(Link)``;

export default Header;
