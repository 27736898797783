import { createGlobalStyle, keyframes } from 'styled-components';

const displaceGlitch = 2;
const glitchDuration = 500;

const glitch = keyframes`
  0% {
    transform: translate(0);
  }
  20% {
    transform: translate(-${displaceGlitch}px, ${displaceGlitch}px);
  }
  40% {
    transform: translate(-${displaceGlitch}px, -${displaceGlitch}px);
  }
  60% {
    transform: translate(${displaceGlitch}px, ${displaceGlitch}px);
  }
  80% {
    transform: translate(${displaceGlitch}px, -${displaceGlitch}px);
  }
  to {
    transform: translate(0);
  }
`;

const GlobalStyle = createGlobalStyle`
@import url('https://fonts.googleapis.com/css?family=Ubuntu+Mono');
@import url('https://rsms.me/inter/inter.css');
html { font-family: Inter,-apple-system,BlinkMacSystemFont,avenir next,avenir,helvetica neue,helvetica,ubuntu,roboto,noto,segoe ui,arial,sans-serif; }
@supports (font-variation-settings: normal) {
  html { font-family: Inter,-apple-system,BlinkMacSystemFont,avenir next,avenir,helvetica neue,helvetica,ubuntu,roboto,noto,segoe ui,arial,sans-serif; }
}
body {
  font-family: Inter,-apple-system,BlinkMacSystemFont,avenir next,avenir,helvetica neue,helvetica,ubuntu,roboto,noto,segoe ui,arial,sans-serif;
  transition: all 600ms ease-in-out;
  background-color: #222;
  color: white;
}
  h1 {
    font-weight: 800;
    text-decoration: none;
    margin: 0;
    color: #fff;
    position: relative;
    &:before, &:after {
      display: block;
      content: attr(data-glitch);
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
      transition: opacity 2000ms ease-in;
      opacity: 0;
    } &:after {
      color: #f0f;
      z-index: -2;
    } &:before {
      color: #0ff;
      z-index: -1;
    }
    &:hover {
      &:before {
        opacity: 0.8;
        animation: ${glitch} ${glitchDuration}ms cubic-bezier(.25, .46, .45, .94) both infinite;
      }
      &:after {
        opacity: 0.8;
        animation: ${glitch} ${glitchDuration}ms cubic-bezier(.25, .46, .45, .94) reverse both infinite;
      }
    }
    a {
      border-bottom: none;
    }
  }
  h2 {
    font-weight: 800;
    font-size: 32px;
  }
  a {
    color: #0093ff;
    text-decoration: none;
    border-bottom: 1px solid #0093ff;
  }
  `;

export default GlobalStyle;
