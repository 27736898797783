import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import Header from '../components/header';
import GlobalStyle from '../styles/globalStyles';

const Layout = ({ location, children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  return (
    <div>
      <GlobalStyle />
      <Header location={location} siteTitle={data.site.siteMetadata.title} />
      <Wrapper>
        <main>{children}</main>
        <Footer>{new Date().getFullYear()} © Paul Dowman</Footer>
      </Wrapper>
    </div>
  );
};

const Footer = styled.footer`
  margin-top: 40px;
  font-family: Inter;
  margin-bottom: 20px;
`;

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 960px;
  padding: 0px 1.0875rem 1.45rem;
  padding-top: 0;
`;

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
